<template>
  <dashboard-card
      :title="title"
      :popover="popover"
  >
    <div>
      <apexchart
          :type="chartOptions.chart.type"
          :height="chartOptions.chart.height"
          :options="chart.options"
          :series="chart.data"
      />
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'
export default {
  name: 'dashboard-line-chart',
  components: { DashboardCard },
  props: {
    title: {
      type: String,
      required: true
    },
    popover: {
      type: String,
      required: true
    },
    seriesTitle: {
      type: String,
      required: true
    },
    seriesData: {
      type: Array,
      required: true
    },
    seriesLabel: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    chartOptions: {
      chart: {
        height: 250,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        // curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: []
        // title: {
        //   text: 'Month'
        // }
      },
      yaxis: {
        title: {
          // text: 'Temperature'
        }
        // min: 5,
        // max: 40
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    }
  }),
  computed: {
    chart () {
      return {
        options: {
          ...this.chartOptions,
          xaxis: { categories: this.seriesLabel },
          yaxis: { title: { text: this.seriesTitle } }
        },
        data: [{
          name: this.seriesTitle,
          data: this.seriesData
        }]
      }
    }
  }
}
</script>

<style scoped>

</style>
