import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function roomMetrics (roomId, { startDate, endDate } = {}) {
  const data = await http.get(`/admin/metrics/rooms/${roomId}/metrics`, {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    quiz: data.quiz ?? 0,
    poll: data.poll ?? 0,
    asking: data.asking ?? 0,
    wordCloud: data.wordCloud ?? 0
  }
}
