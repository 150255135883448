<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Visualizações totais"
            popover="Visualizações totais do quarto"
        >{{ visualizacoesTotais }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Acessos únicos"
            popover="Acessos únicos do quarto"
        >{{ acessosUnicos }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Pico de usuários simultâneos"
            popover="Pico de usuários simultâneos do quarto"
        >{{ picoUsuarios }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de palavras núvem"
            popover="Total de palavras núvem do quarto"
        >{{ nuvemPalavras }}
        </dashboard-big-number-a>
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col cols="12" xl="9" class="mb-3">
        <dashboard-line-chart
            class="h-100"
            title="Evolução de inscritos"
            popover="Evolução de inscritos"
            series-title="online"
            :series-data="evolucaoInscritosGraph.dataList"
            :series-label="evolucaoInscritosGraph.labelList"
        />
      </b-col>
      <b-col cols="12" xl="3" class="mb-3 d-flex flex-column">
        <b-row class="mb-3">
          <b-col>
            <dashboard-big-number-a
                title="Total de mensagens chat"
                popover="Total de mensagens chat"
            >{{ totalMensagensChat }}
            </dashboard-big-number-a>
          </b-col>
        </b-row>
        <b-row class="flex-grow-1">
          <b-col>
            <dashboard-card
                title="Taxa de reações"
                popover="Taxa de reações"
                class="h-100"
            >
              <div class="h-100 tx-reacao">
                <b-row class="flex-grow-1">
                  <b-col cols="6" v-for="(item, index) in reactionList" :key="'reaction-'+index">
                    {{ item.reaction }} {{ item.count }}
                  </b-col>
                </b-row>
                <hr>
                <div style="display: grid; place-items: center">
                  <div class="text-center">
                    {{ totalReactions }}
                    <div class="small">
                      Reações
                    </div>
                  </div>
                </div>
              </div>
            </dashboard-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de perguntas recebidas"
            popover="Total de perguntas recebidas"
        >{{ totalPerguntas }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de quiz respondidos"
            popover="Total de quiz respondidos"
        >{{ totalQuiz }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de enquetes respondidas"
            popover="Total de enquetes respondidas"
        >{{ totalEnquetesRespondidas }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Total de palavras núvem"
            popover="Total de palavras núvem"
        >{{ totalNuvemPalavras }}
        </dashboard-big-number-a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardBigNumberA from '@/components/dashboards/dashboard-big-number-a'
import DashboardLineChart from '@/components/dashboards/dashboard-line-chart'
import DashboardCard from '@/components/dashboards/dashboard-card'
import { roomVisits } from '@/modules/dashboards/http/roomVisits'
import { roomMetrics } from '@/modules/dashboards/http/roomMetrics'
import { roomGraphUsers } from '@/modules/dashboards/http/roomGraphUsers'
import { reactEmojis } from '@/modules/dashboards/http/reactEmojis'

export default {
  name: 'RoomDetail',
  components: { DashboardCard, DashboardLineChart, DashboardBigNumberA },
  data: () => ({
    roomId: null,
    visualizacoesTotais: '',
    acessosUnicos: '',
    picoUsuarios: '',
    nuvemPalavras: '',
    totalMensagensChat: 'Dado indisponível',
    evolucaoInscritosGraph: {
      dataList: [],
      labelList: []
    },
    reactionList: [],
    totalReactions: '',
    totalPerguntas: '',
    totalQuiz: '',
    totalEnquetesRespondidas: '',
    totalNuvemPalavras: ''
  }),
  computed: {
    filterDates () {
      return {
        startDate: this.$store.state.dashboards.filterStartDate,
        endDate: this.$store.state.dashboards.filterEndDates
      }
    }
  },
  methods: {
    fetchData () {
      this.roomId = this.$route.params.id
      Promise.allSettled([
        this.fetchRoomVisits(),
        this.fetchRoomMetrics(),
        this.fetchGraphData(),
        this.fetchEmojiReactions()
      ])
    },
    async fetchRoomVisits () {
      const data = await roomVisits(this.roomId, this.filterDates)
      this.visualizacoesTotais = data.totalViews
      this.acessosUnicos = data.totalUnique
      this.picoUsuarios = data.totalPeak
    },
    async fetchRoomMetrics () {
      const data = await roomMetrics(this.roomId, this.filterDates)
      this.totalPerguntas = data.asking
      this.totalQuiz = data.quiz
      this.totalEnquetesRespondidas = data.poll
      this.totalNuvemPalavras = data.wordCloud
      this.nuvemPalavras = data.wordCloud
    },
    async fetchGraphData () {
      const data = await roomGraphUsers(this.roomId, this.filterDates)
      this.evolucaoInscritosGraph.dataList = data.list
      this.evolucaoInscritosGraph.labelList = data.label
    },
    async fetchEmojiReactions () {
      const data = await reactEmojis({ roomId: this.roomId, ...this.filterDates })
      this.reactionList = [
        { reaction: '👍', count: data.likes },
        { reaction: '😍', count: data.love },
        { reaction: '🚀', count: data.rocket },
        { reaction: '😮', count: data.wow },
        { reaction: '👏', count: data.hands },
        { reaction: '😆', count: data.incredible }
      ]
      this.totalReactions = data.likes + data.love + data.rocket + data.wow + data.hands + data.incredible
    }
  },
  watch: {
    filterDates: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      { immediate: true }
    )
  }
}
</script>

<style scoped lang="scss">
.tx-reacao {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 600;
  color: #2F3844;

  hr {
    width: 100%;
  }
}

</style>
